<template>
    <admin-template>
        <template slot="drawers">
            <v-navigation-drawer v-model="showDetailsDrawer" right temporary fixed width="500">
                <provider-detail :provider="selectedProvider"></provider-detail>
            </v-navigation-drawer>
        </template>
        <template slot="content">
            <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                <v-toolbar-title>
                    <h1 class="headline">Proveedores</h1>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-toolbar-title>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                x-small
                                color="primary"
                                class="elevation-0"
                                :to="{ name: 'providerCreate' }"
                                v-on="on"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Agregar proveedor</span>
                    </v-tooltip>
                </v-toolbar-title>
            </v-toolbar>

            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-text>
                            <v-data-table
                                :items="providers"
                                :loading="isLoading"
                                :headers="headers"
                                :items-per-page="-1"
                                hide-default-header
                                hide-default-footer
                                disable-sort
                            >
                                <template slot="item" slot-scope="props">
                                    <tr v-if="props.item" @click="onProviderSelected(props.item)">
                                        <td>{{ props.item.name }} ({{ props.item.companyName }})</td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </admin-template>
</template>

<script>
import ProviderDetail from './ProviderDetail';
import AdminTemplate from '../../Admin/AdminTemplate.vue';

export default {
    components: {
        ProviderDetail,
        AdminTemplate
    },

    data() {
        return {
            showDetailsDrawer: false,
            selectedProvider: null,

            isLoading: false,

            headers: [
                { text: 'Nombre', value: 'name' },
            ]
        };
    },

    watch: {},

    computed: {
        providers() {
            return this.$store.getters['providers/getAll']();
        }
    },

    mounted() {
        this.fetchProviders();
    },

    methods: {
        onNewSelected() {
            this.showCreateForm = true;
        },

        onProviderSelected(provider) {
            this.showDetailsDrawer = true;
            this.selectedProvider = provider;
        },

        fetchProviders() {
            this.isLoading = true;
            this.$store.dispatch('providers/fetchAll').finally(() => {
                this.isLoading = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
