<template>
    <v-container v-if="provider">
        <v-row align="center" class="header">
            <v-col>
                <v-row>
                    <v-col class="headline blue-grey--text text--darken-3">
                        {{ provider.name | capitalize }}
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-list dense>
                    <v-list-item>
                        <v-list-item-content>Razón Social</v-list-item-content>
                        <v-list-item-content>{{ provider.companyName }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Dirección</v-list-item-content>
                        <v-list-item-content>{{ provider.address }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Contacto</v-list-item-content>
                        <v-list-item-content>{{ provider.contactName }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Teléfono</v-list-item-content>
                        <v-list-item-content>{{ provider.phoneNumber }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Email</v-list-item-content>
                        <v-list-item-content>{{ provider.emailAddress }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Sitio Web</v-list-item-content>
                        <v-list-item-content>{{ provider.website }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Observación</v-list-item-content>
                        <v-list-item-content>{{ provider.observation }}</v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        components: {},

        props: {
            provider: Object
        },

        data() {
            return {};
        },

        methods: {
            onEditSelected() {
                this.$router.push({ name: 'productEdit', params: { id: this.product.id } });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .info-list {
        font-size: 13px;
        padding-left: 30px !important;

        .flex {
            &.xs2, &.xs10 {
                padding-top: 0.2em;
            }
        }
    }

    .v-chip.small {
        height: 20px;
    }
</style>
